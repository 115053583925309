<template>
  <div class="dailyWrap" v-loading="loadShow">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="dailyBar mb20" :style="{backgroundImage: 'url('+dailyBg+')'}">
          <div class="dailyMain">
            <div class="title mb20"><img :src="title" alt="每日日常"></div>
            <div class="taskWrap">
              <div class="item" v-for="(item,index) in taskList">
                <div class="task">
                  <div class="name" :style="{backgroundImage: 'url('+titleBg+')'}">{{item.taskName}}</div>
                  <div class="nameDes">任务要求：{{item.descprition}}</div>
                </div>
                <div class="pic"><img :src="item.taskPrizeImg" alt=""><span class="count">x {{item.prizeNum}}</span></div>
                <div class="btnWrap">
                  <span class="btn" v-if="item.isClick == 1" @click="receive(item)">{{item.btnText}}</span>
                  <span class="btn" v-else-if="item.isClick==0 && item.btnUrl!=''" @click="goShop(item)">{{item.btnText}}</span>
                  <span class="btn btn1" v-else>{{item.btnText}}</span>
                </div>
              </div>
            </div>
            <div class="rulesWrap">
              <div class="tit">规则说明：</div>
              <div class="cons">
                <p>1）每日任务每天可以完成一次。</p>
                <p>2）每日任务每天0点重置任务奖励。</p>
                <p>3）每日任务奖励的绑定白金币、幸运钥匙、精品服饰兑换券领取成功后请于网站商城—个人仓库中查看。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import menuWX from '@/components/Menu'
  import downItemWX from '@/views/downLoad/item'
  import axios from "axios";
  import Cookies from "js-cookie";
  export default {
    name: 'daily',
    components:{
      menuWX,
      downItemWX,
    },
    data(){
      return {
        loadShow:false,
        dailyBg:require('@/assets/imgs/daily/dailyBg.png'),
        title:require('@/assets/imgs/daily/title.png'),
        titleBg:require('@/assets/imgs/daily/titleBg.png'),

        taskList:[],
      }
    },
    created(){
      this.getTaskList()
    },
    mounted() {
      document.documentElement.scrollTop = 0
    },
    methods: {
      getTaskList(){
        this.loadShow = true
        axios({
          method: 'post',
          url: '/task/getTaskList',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            platForm:'web',
            userId: Cookies.get('wx_userId'),      // userId
          },
        }).then((res) => {
          this.loadShow = false
          if(res.data.state == 200){
            this.taskList = res.data.data.taskConfigList
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },      // 获取招募列表 - 任务管理
      receive(item){
        axios({
          method: 'post',
          url: '/task/getTaskPrize',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            taskId:item.taskId,
            platForm:'web',
            userId: Cookies.get('wx_userId'),      // userId
          },
        }).then((res) => {
          if(res.data.state == 200){
            this.$message.success(res.data.message)
            this.getTaskList()
            this.getUserInfo()
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },
      goShop(item){
        if(item.btnUrl.indexOf('http')!=-1){
          window.open(item.btnUrl,'_blank')
        }else{
          this.$router.push({
            path:item.btnUrl,
          })
        }
      },           // 去购买 - 进入商城盲盒页面
      getUserInfo(){// 获取用户信息
        axios({
          method: 'post',
          url: '/user/getMemberInfo',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            platForm:'web',
            userId:Cookies.get('wx_userId')
          },
        }).then(res => {
          if(res.data.state == 200){
            if(res.data&&res.data.data){
              this.userInfo=res.data.data
              this.resetSetItem('userInfo', JSON.stringify(res.data.data))
            }
          }else{
            this.$message.error(res.data.message)
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .dailyWrap{
    .dailyBar{
      padding:40px 40px 50px 40px;
      height: 1000px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      .dailyMain{
        .title{
          text-align: center;
        }
        .taskWrap{
          height: 570px;
          padding-bottom: 10px;
          box-sizing: border-box;
          .item{
            margin-bottom: 10px;
            height: 100px;
            background: rgba(255,255,255,0.5);
            box-shadow: 0 4px 10px 0 rgba(135,135,135,0.25);
            border-radius: 0;
            border: 1px dashed #999999;
            display: flex;
            .task{
              padding-right: 20px;
              flex: 1;
              .name{
                padding-left:20px;
                height: 27px;
                line-height: 27px;
                color: #ffffff;
                font-size: 18px;
                margin-bottom: 30px;
                background-repeat: no-repeat;
              }
              .nameDes{
                padding-left:20px;
                font-size: 20px;
                font-weight: 700;
              }
            }
            .pic{
              margin:10px 0;
              padding:10px;
              width: 80px;
              height: 80px;
              border:1px solid #D22190;
              box-sizing: border-box;
              position: relative;
              z-index: 1;
              img{
                width: 60px;
                max-height: 60px;
                border:1px dashed #dddddd;
              }
              .count{
                position: absolute;
                right: 3px;
                bottom: 0;
                z-index: 2;
                font-size: 16px;
                font-weight: 700;
              }
            }
            .btnWrap{
              margin-left:50px;
              padding-right: 20px;
              line-height: 100px;
              .btn{
                display: inline-block;
                font-size: 20px;
                width: 126px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                background: #D22190;
                color: #ffffff;
                border-radius: 4px;
                cursor: pointer;
              }
              .btn1{
                background: #808080;
                cursor: default;
              }
            }
          }
        }
        .rulesWrap {
          padding:20px;
          background: rgba(255,255,255,0.5);
          box-shadow: 0 4px 10px 0 rgba(135,135,135,0.25);
          border-radius: 4px;
          border: 1px solid #D22190;
          .tit{
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
          }
          .cons{
            line-height: 30px;
            p{
              font-size: 16px;
            }
          }
        }
      }
    }
  }
</style>
